<ng-container *ngIf="loading">
  <app-progress-loader [isLoading]="loading"></app-progress-loader>
</ng-container>

<ng-container *ngIf="!loading">
  <ng-container *ngIf="isAuthorized; else unauthorized">
    <router-outlet></router-outlet>
  </ng-container>

  <ng-template #unauthorized>
    <app-unauthorized></app-unauthorized>
  </ng-template>
</ng-container>
