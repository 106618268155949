import { Component } from "@angular/core";
import { AuthenticationService } from "./core/services/authentication.service";
import { filter, Subscription } from "rxjs";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { EventMessage, EventType } from "@azure/msal-browser";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  title = "Executive Business Review";
  accountInfo: any;
  private accountInfoSubscription: Subscription;
  isMsalLoggedIn: boolean = false;
  status: boolean = false;
  isAuthorized: boolean;
  loading: boolean = true;

  constructor(
    private authService: AuthenticationService,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService
  ) {
    this.isMsalLoggedIn = false;
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        this.isMsalLoggedIn = true;
        this.accountInfo = result;
        sessionStorage.setItem("accountInfo", JSON.stringify(result));
        this.authService.logoutEBR(false);

        this.authService
          .updateUserLoginStatus(
            this.msalService?.instance?.getAllAccounts()?.[0]?.localAccountId,
            false
          )
          .subscribe({});
      });
  }

  ngOnInit() {
    this.authService.isUnauthorized().subscribe((res) => {
      this.isAuthorized = res.response.isAuthorized;
      this.loading = false;
    });

    if (!this.isMsalLoggedIn) {
      this.authService.checkLoggedOutService().subscribe((info) => {
        this.status = info?.data?.login?.isLoggedOut;
        if (this.status) {
          this.authService.logoutEBR(true);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.accountInfoSubscription) {
      this.accountInfoSubscription.unsubscribe();
    }
  }
}
